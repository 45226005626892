import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getRoles(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/administrador/roles', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPermisos() {
      return new Promise((resolve, reject) => {
        axios
          .get('/administrador/listar/permisos')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRol(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/administrador/roles', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRol(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/administrador/roles/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
