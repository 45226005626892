<template>
  <b-modal
    id="modal_nuevo_rol"
    ok-only
    size="lg"
    :header-bg-variant="cabezeraModal"
    no-close-on-backdrop
  >
    <template
      #modal-header
    >
      <div class="text-white">
        <strong class="float-left">
          {{ nombreModal }}
        </strong></div>

    </template>
    <b-row>
      <b-col cols="12">
        <label for="">Nombre del rol:</label>
        <b-form-input
          v-model="dataRol.name"
          :disabled="!vamosCrear"
        />

      </b-col>
      <b-col cols="12">
        <label for="">Permisos:</label>
        <v-select
          v-model="dataRol.permissions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          label="label"
          :options="option"
          class="mb-2"
        />
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="d-flex justify-content-between">

        <b-button
          size="sm"
          variant="outline-danger"
          class="mr-1"
          @click="() => $bvModal.hide('modal_nuevo_rol')"
        >
          Cancelar
        </b-button>

        <b-button
          size="sm"
          variant="outline-primary"
          @click="onSubmit"
        >
          {{ nombreBotonModal }}
        </b-button>

      </div>
    </template>
  </b-modal>
</template>

<script>

import store from '@/store'
import {
  BButton, BModal, BCol, BRow, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import vSelect from 'vue-select'

export default {
  components: {
    BFormInput,
    BCol,
    BRow,
    BModal,
    vSelect,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dir: 'ltr',

      option: [],
      cabezeraModal: '',
      vamosCrear: true,

      dataRol: { name: '', permissions: [] },
      idUpdate: 0,
    }
  },
  async mounted() {
    await store.dispatch('roles-store-module/getPermisos').then(res => {
      this.option = res.data.map(val => ({ label: val.name, value: val.id }))
    })
  },
  created() {
    this.$parent.$on('editarRol', this.editarRol)
    this.$parent.$on('nuevoRol', this.nuevoRol)
  },
  methods: {
    nuevoRol() {
      this.nombreModal = 'Nuevo Rol'
      this.nombreBotonModal = 'Grabar'
      this.cabezeraModal = 'primary'
      this.vamosCrear = true
      this.dataRol.name = ''
      this.dataRol.permissions = []

      this.$bvModal.show('modal_nuevo_rol')
    },
    editarRol(data) {
      this.nombreModal = 'Actualizar Rol'
      this.nombreBotonModal = 'Actualizar'
      this.cabezeraModal = 'warning'
      this.vamosCrear = false
      this.idUpdate = data.id
      this.dataRol.name = data.name
      this.dataRol.permissions = data.permissions.map(val => ({ label: val.name, value: val.id }))

      this.$bvModal.show('modal_nuevo_rol')
    },
    onSubmit() {
      if (this.vamosCrear) {
        this.createNuevoRol()
      } else {
        this.updateRol()
      }
    },
    createNuevoRol() {
      const DATA = {
        name: this.dataRol.name,
        permissions: this.dataRol.permissions.map(val => val.value),
      }
      store.dispatch('roles-store-module/addRol', DATA)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Rol registrado!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$emit('actualizarTablaRoles')
          this.$bvModal.hide('modal_nuevo_rol')
        }).catch(error => {
          if (error.response.data.error !== undefined) {
            Object.entries(error.response.data.error).forEach(([key, value]) => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${key}: ${value[0]}`,
                  icon: 'CheckCircleIcon',
                  variant: 'warning',
                },
              })
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error en actualizar',
                icon: 'CheckCircleIcon',
                variant: 'warning',
              },
            })
          }
        })
    },
    updateRol() {
      const DATA = {
        id: this.idUpdate,
        data: {
          name: this.dataRol.name,
          permissions: this.dataRol.permissions.map(val => val.value),
        },
      }

      store.dispatch('roles-store-module/updateRol', DATA)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Rol actualizado!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$emit('actualizarTablaRoles')
          this.$bvModal.hide('modal_nuevo_rol')
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Error al actualizar!',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
        })
    },

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
