<template>
  <div>
    <modal-rol-nuevo @actualizarTablaRoles="actualizarTablaRoles" />
    <b-card class="mt-0 px-2">

      <b-table
        id="my-table-roles"
        ref="refTablaRoles"
        :no-provider-filtering="true"
        class="position-relative"
        show-empty
        head-variant="dark"
        hover
        small
        responsive
        bordered
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="fields"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        caption-top
      >
        <template #table-caption>
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="4"
              sm="12"
            >
              <span class="text-dark"><strong>LISTA DE ROLES</strong> </span>
            </b-col>
            <b-col
              xl="8"
              lg="8"
              md="8"
              sm="12"
              class="d-flex justify-content-end"
            >

              <div class="mr-1">
                <label>Mostrar</label>
                <b-form-select
                  v-model="perPage"
                  :options="pageOptions"
                  :clearable="false"
                  size="md"
                  style="width:auto"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>registros</label>
              </div>
              <div class="mr-1">
                <b-form-input
                  v-model="filter"
                  type="search"
                  placeholder="Buscar..."
                  size="md"
                />
              </div>
              <div>
                <b-button
                  variant="primary"
                  @click="agregarNuevoRol"
                >
                  Nuevo rol
                </b-button>
              </div>
            </b-col>

          </b-row>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> cargando...</strong>
          </div>
        </template>
        <template #cell(index)="row">
          {{ from + row.index }}
        </template>

        <template #cell(rol)="row">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :variant="`light-primary`"
                :text="avatarText(recorteName(row.item.name))"
              />
            </template>
            <b-link
              class="font-weight-bold text-nowrap"
            >
              {{ row.item.name }}
            </b-link>
          </b-media>
        </template>
        <template #cell(permisos)="row">

          <div
            v-for="(rol,index) in row.item.permissions"
            :key="index"
          >
            <b-badge variant="light-primary">
              {{ rol.name }}
            </b-badge>

          </div>

        </template>
        <!-- column: acciones -->
        <template #cell(acciones)="row">
          <b-button
            variant="warning"
            class="btn-icon rounded-circle"
            size="sm"
            @click="editarRol(row.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BSpinner, BRow, BCol, BPagination, BFormInput,
  BButton, BMedia, BAvatar, BLink, BFormSelect, BBadge,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import ModalRolNuevo from './ModalRolNuevo.vue'

import rolStoreModule from './rolStoreModule'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    BFormSelect,
    BRow,
    BCol,
    BFormInput,
    BPagination,
    BButton,
    BMedia,
    BAvatar,
    BLink,

    ModalRolNuevo,
    BBadge,

  },
  data() {
    return {
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 50],
      sortBy: 'id',
      sortDesc: false,
      filter: '',
      filterOn: [],

    }
  },
  methods: {
    actualizarTablaRoles() {
      this.filter = null
      this.$refs.refTablaRoles.refresh()
    },
    agregarNuevoRol() {
      this.$emit('nuevoRol')
    },
    editarRol(item) {
      this.$emit('editarRol', item)
    },

    async myProvider(ctx) {
      const promise = store.dispatch('roles-store-module/getRoles', { queryParams: ctx })

      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data
        const items = data
        this.totalRows = total || 0
        this.from = from || 0
        this.to = to || 0

        return items || []
      })
    },

    recorteName(name) {
      let recorte = []
      recorte = name.split(' ')
      return `${recorte[0]} ${recorte[1]}`
    },
  },
  setup() {
    const ROLES_APP_STORE_MODULE_NAME = 'roles-store-module'

    // Register module
    if (!store.hasModule(ROLES_APP_STORE_MODULE_NAME)) store.registerModule(ROLES_APP_STORE_MODULE_NAME, rolStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROLES_APP_STORE_MODULE_NAME)) store.unregisterModule(ROLES_APP_STORE_MODULE_NAME)
    })

    const fields = [
      { key: 'index', label: 'N°', sortable: true },
      { key: 'rol', label: 'ROL', sortable: true },
      { key: 'permisos', label: 'PERMISOS', sortable: true },
      { key: 'acciones' },
    ]
    return {
      avatarText,
      fields,
      rolStoreModule,
    }
  },
}
</script>
<style lang="scss">
</style>
